import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/IndexView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/SignupView.vue')
  },
  {
    path: '/smsLogin',
    name: 'smsLogin',
    component: () => import('../views/SmsLoginView.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    children: [
      {
        path: '/home',
        name: 'nav',
        component: () => import('../views/NavView.vue')
      },
      {
        path: '/ai/xhs',
        name: 'xhs',
        component: () => import('../views/write/XhsView.vue')
      },
      {
        path: '/ai/common',
        name: 'common',
        component: () => import('../views/write/CommonView.vue')
      },
      {
        path: '/pay',
        name: 'pay',
        component: () => import('../views/PayView.vue')
      },
      {
        path: '/feedback',
        name: 'feedback',
        component: () => import('../views/FeedbackView.vue')
      },
      {
        path: '/gift/new',
        name: 'giftNew',
        component: () => import('../views/gift/NewView.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
      },
      {
        path: '/user/history',
        name: 'history',
        component: () => import('../views/history/IndexView.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
